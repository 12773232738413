import { Link } from 'react-router-dom';
import huji from 'assets/images/hero-huji.png';
import diecc from 'assets/images/hero-diecc.png';

export default function Hero() {
  return (
    <header className="hero">
      <div className="container px-4 lg:py-20 py-10 text-white">
        <div className="pb-8 text-center lg:text-left">
          <h1>Oferta Académica</h1>
        </div>
        <div className="grid lg:grid-cols-2 lg:mb-[76px]">
          <div className="flex flex-col items-center justify-center gap-10">
            <h3 className="text-center">Introducción a la Endocannabinología,<br /> Cannabis y Cannabinoides</h3>
            <Link
              to="/yissum"
              className="bg-white text-brand-pure-blue border-2 border-brand-pure-blue mb-12 lg:mb-0 px-8 py-4 font-bold uppercase">
              ver curso
            </Link>
          </div>
          <div className="flex items-center justify-center order-first lg:order-last mb-12 lg:mb-0">
            <img src={huji} alt="" width={580} className="w-full max-w-[580px]" />
          </div>
        </div>
        <div className="grid lg:grid-cols-2">
          <div className="flex items-center justify-center">
            <img src={diecc} alt="" width={580} className="w-full max-w-[580px] mb-12 lg:mb-0" />
          </div>
          <div className="flex flex-col items-center justify-center gap-10">
            <h3 className="text-center">
              Formación Integral y de<br />
              Profundización a la<br />
              Endocannabinología, Cannabis y<br />
              Cannabinoides
            </h3>
            <Link
              to="http://diecc.org/"
              className="bg-white text-brand-pure-blue border-2 border-brand-pure-blue mb-12 lg:mb-0 px-8 py-4 font-bold uppercase">
              ver diplomado
            </Link>
          </div>
        </div>
      </div>
    </header>
  )
}