import Nav from 'components/Nav';
import Hero from 'components/Hero';
import Footer from 'components/Footer';
import Intro from 'components/Intro';
import Who from 'components/Who';
import Mision from 'components/Mision';
import Contact from 'components/Contact';

export default function Home() {
  return (
    <>
      <Nav />
      <main>
        <Hero />
        <Intro />
        <Who />
        <Mision />
        <Contact />
      </main>
      <Footer />
    </>
  );
}