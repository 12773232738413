import logo from 'assets/images/logo.png';

export default function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer>
      <div className="container flex flex-col items-center justify-center gap-3">
        <img src={logo} width={200} alt="Pharma Origins Education" />
        <p>© Pharma Origins - Copyright | {year}</p>
      </div>
    </footer>
  );
}