export default function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer className="bg-brand-green py-8">
      <div className="container flex items-center justify-center">
        <p>© Pharma Origins - Copyright | {year}</p>
      </div>
    </footer>
  );
}