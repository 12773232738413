import axios from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast, { Toaster } from 'react-hot-toast';
import spinner from 'assets/images/spinner.svg';

export default function Contact() {
  const { sheety } = require('../configs/global');

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    setLoading(true);

    axios.post(sheety, {
      contact: {
        'name': data.name,
        'email': data.email,
        'message': data.message
      },
    }).then(response => {
      if (response.status === 200) {
        setDisabled(true);
        toast.success('Su consulta ha sido enviada correctamente.');
      }
    }).catch(() => {
      toast.error('Error al enviar su consulta.');
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <section className="bg-brand-light-blue scroll-mt-[80px] lg:scroll-mt-[100px]" id="contactenos">
      <Toaster
        position="top-center"
        reverseOrder={true} />
      <div className="container px-6 py-12 lg:w-2/3 lg:pt-[166px] lg:pb-[108px]">
        <h2 className="text-brand-dark-blue">Contacto</h2>
        <p className="py-6 text-lg font-roboto text-brand-dark-blue">Si usted tiene alguna consulta, por favor complete el formulario a continuación.</p>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-6 pt-12 lg:pb-[100px]">
          <div className="grid lg:grid-cols-2 gap-6">
            <div className="flex flex-col gap-1.5">
              <label htmlFor="name">Nombre completo (Nombre y apellidos)</label>
              <input type="text" id="name" {...register('name', { required: true })} className={errors.name && 'error'} />
            </div>
            <div className="flex flex-col gap-1.5">
              <label htmlFor="email">Correo electrónico</label>
              <input type="email" id="email" {...register('email', { required: true })} className={errors.email && 'error'} />
            </div>
          </div>
          <div className="flex flex-col gap-1.5">
            <label htmlFor="message">Escribe su consulta</label>
            <textarea id="message" rows={5} {...register('message', { required: true })} className={errors.message && 'error'} />
          </div>
          <div>
            <button
              type="submit"
              className="w-full lg:w-auto disabled:bg-opacity-80 flex justify-center items-center transition duration-300"
              disabled={disabled || loading}>
              <div className="absolute">
                <img
                  src={spinner}
                  alt=''
                  width={24}
                  height={24}
                  className={loading ? 'visible' : 'invisible'} />
              </div>
              <div className={loading ? 'invisible' : 'visible'}>
                Enviar consulta
              </div>
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}