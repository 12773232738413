export default function More() {
  return (
    <section className="bg-brand-green bg-opacity-20">
      <div className="container py-10 px-6 lg:px-0">
        <h4 className="text-[24px] font-bold mb-5">Cannabis medicinal</h4>

        <div className="flex flex-col gap-5">
          <p>El curso pretende educar a los profesionales de la salud sobre el papel terapéutico del cannabis medicinal en la salud del paciente. El cannabis tiene muchos usos médicos, desde el tratamiento de adultos con náuseas y vómitos inducidos por la quimioterapia  chemotherapy-induced nausea and vomiting, hasta el tratamiento del dolor crónico y el tratamiento de adultos con espasticidad relacionada con la esclerosis múltiple (EM)  multiple sclerosis (MS)-related spasticity. El cannabis es una nueva frontera en el mundo de la medicina, que contiene un tesoro de remedios medicinales.</p>

          <p>Desarrollado por expertos del MCCR y producido por la empresa de transferencia de tecnología de la Universidad Hebrea, Yissum, el curso virtual se distribuirá para el mercado mundial en inglés y español. El curso, que consta de 13 segmentos en línea, ofrece a la comunidad médica una visión y comprensión en profundidad de los beneficios del cannabis medicinal. El programa del curso abarca la historia médica del cannabis, los tratamientos, la seguridad de los medicamentos, los avances de la investigación, los usos medicinales y las prácticas, con el objetivo de proporcionar una formación completa sobre el cannabis a los profesionales de la medicina.</p>

          <p>El Centro Multidisciplinar para la Investigación del Cannabis (MCRR) de la Universidad Hebrea es la institución académica y de investigación líder en el mundo con el objetivo de elevar el uso de los cannabinoides para el tratamiento médico profesional convencional y la mejora final de la salud humana.
            El MCCR lidera una nueva era en la investigación del Cannabis Medicinal a través de la promoción de proyectos de investigación científica traslacional, así como la investigación preclínica y clínica, tanto a nivel nacional como internacional, con el objetivo de comprender mejor cómo los cannabinoides son relevantes para diversas enfermedades a nivel químico, biológico, agrícola y celular.</p>

          <p>
            - El MCCR forma a jóvenes científicos y concede becas y/o premios para animar a los estudiantes de máster y doctorado a centrar su investigación en los cannabinoides.<br />
            - El MCCR organiza un simposio anual sobre cannabinoides que reunirá a estudiantes e investigadores de Israel y apoyará una conferencia anual de un distinguido científico invitado del extranjero. Infórmese ahora sobre el simposio de 2021.<br />
            - El MCCR fomenta la interacción con farmacéuticas internacionales para convertir los logros científicos en el campo del cannabis y los cannabinoides en un potencial valor comercial.<br />
            - El MCRR cultiva la interacción con otros centros de investigación, así como con instituciones educativas en el extranjero, promoviendo seminarios y talleres conjuntos.
          </p>

          <p>Siguiendo la línea de la experiencia y los objetivos del Centro Multidisciplinar para la Investigación del Cannabis, ofrecemos a los profesionales médicos de todo el mundo un curso online a medida que explique los últimos conocimientos científicos sobre el cannabis medicinal y cómo utilizarlo para el cuidado de los pacientes.</p>

          <p>Investigadores de alto nivel del MCCR ofrecen sesiones sobre:</p>

          <p>
            - Historia del uso del Cannabis<br />
            - Bioquímica de compuestos naturales y sintéticos<br />
            - Métodos y tecnologías agrícolas<br />
            - Farmacocinética<br />
            - Compuestos del cannabis<br />
            - Efectos secundarios y contraindicaciones<br />
            - Principales indicaciones: dolor, ansiedad, cáncer, neurología, gastroenterología y otras<br />
            - Riesgo de adicción<br />
            - Papel de los distintos profesionales médicos en el tratamiento con Cannabis<br />
            - Últimos temas en la investigación del cannabis.
          </p>

          <p>La participación en el curso le da derecho a:</p>

          <p>
            - Tres meses de exclusividad ilimitada a las clases virtuales.<br />
            - Examen de cualificación opcional. La superación del examen da derecho a un certificado oficial personalizado (con su nombre) emitido por el MCCR (Centro Multidisciplinar de Investigación de Cannabinoides de la Universidad Hebrea)<br />
            - El examen final puede realizarse un máximo de dos veces en un semestre. Si no apruebas la primera vez puedes volver a ver los vídeos antes del segundo examen.
          </p>
        </div>
      </div>
    </section>
  );
}