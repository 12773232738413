import axios from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast, { Toaster } from 'react-hot-toast';
import spinner from 'assets/images/spinner.svg';

export default function Contact() {
  const { enroll, api } = require('../../configs/global');
  const countries = require('../../data/countries');

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm();

  const onSubmit = (data) => {
    setLoading(true);

    axios.post(api, {
      'full_name': data.name,
      'country_of_residence': data.country,
      'document_type': data.id_type,
      'document_number': data.id,
      'contact_phone': data.phone,
      'profession': data.occupation,
      'email': data.email,
      'mentoring': data.mentoring === 'online_mentoring'
    }).then(response => {
      if (response.status === 200) {
        setDisabled(true);
        toast.success('Solicitud enviada correctamente.')
      }
    }).catch(() => {
      toast.error('Error al enviar su solicitud.');
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <section className="scroll-mt-[80px] lg:scroll-mt-[100px]" id="inscripcion">
      <Toaster
        position="top-center"
        reverseOrder={true} />
      <div className="container px-6 py-12 lg:w-2/3 lg:max-w-[1000px]">
        <h2 className="">Inscripción</h2>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-4 pt-12 pb-6">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div className="flex flex-col gap-1.5">
              <label htmlFor="name">Nombre completo (Nombre y apellidos)</label>
              <input type="text" id="name" {...register('name', { required: true })} className={errors.name && 'error'} />
            </div>
            <div className="flex flex-col gap-1.5">
              <label htmlFor="country">País de residencia</label>
              <select id="country" {...register('country', { required: true })} className={errors.country && 'error'}>
                <option selected={true} disabled="disabled"></option>
                {countries.map((country) => <option key={country.iso2} value={country.iso2}>{country.nameES}</option>)}
              </select>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div className="flex flex-col gap-1.5">
              <label htmlFor="id_type">Tipo de documento</label>
              <select id="id_type" {...register('id_type', { required: true })} className={errors.id_type && 'error'}>
                <option selected={true} disabled="disabled"></option>
                {enroll.id_types.map((type) => <option key={type.value} value={type.value}>{type.option}</option>)}
              </select>
            </div>
            <div className="flex flex-col gap-1.5">
              <label htmlFor="id">Número de documento</label>
              <input type="number" id="id" {...register('id', { required: true })} className={errors.id && 'error'} />
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div className="flex flex-col gap-1.5">
              <label htmlFor="phone">Teléfono de contacto</label>
              <input type="phone" id="phone" {...register('phone', { required: true })} className={errors.phone && 'error'} />
            </div>
            <div className="flex flex-col gap-1.5">
              <label htmlFor="occupation">Profesión</label>
              <input type="text" id="occupation" {...register('occupation', { required: true })} className={errors.occupation && 'error'} />
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div className="flex flex-col gap-1.5">
              <label htmlFor="email">Correo electrónico</label>
              <input type="email" id="email" {...register('email', { required: true })} className={errors.email && 'error'} />
            </div>
            <div className="flex flex-col gap-1.5">
              <label htmlFor="email_repeat">Repetir correo electrónico</label>
              <input
                type="email"
                id="email_repeat"
                {...register('email_repeat', {
                  required: true,
                  validate: (val) => {
                    if (watch('email') !== val) {
                      return 'Los emails con coinciden'
                    }
                  }
                })}
                className={errors.email_repeat && 'error'} />
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div className="flex flex-col gap-1.5">
              <label htmlFor="mentoring">Modalidad</label>
              <select id="mentoring" {...register('mentoring', { required: true })} className={errors.mentoring && 'error'}>
                <option selected={true} disabled="disabled"></option>
                {enroll.type.map((type) => <option key={type.value} value={type.value}>{type.option}</option>)}
              </select>
            </div>
            <div className="flex flex-col gap-1.5"></div>
          </div>
          <div className="mt-4">
            <button
              type="submit"
              className="w-full lg:w-auto bg-brand-green text-lg disabled:bg-opacity-80 flex justify-center items-center"
              disabled={disabled || loading}>
              <div className="absolute">
                <img
                  src={spinner}
                  alt=''
                  width={24}
                  height={24}
                  className={loading ? 'visible' : 'invisible'} />
              </div>
              <div className={loading ? 'invisible' : 'visible'}>
                Enviar solicitud
              </div>
            </button>
          </div>
        </form>
        <p className="text-base lg:pb-[100px]">
          *Luego de enviada la solicitud se le enviara un correo electronico con la informacion necesaria para reliazar el pago.
        </p>
      </div>
    </section>
  );
}