import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Hamburguer from 'components/Hamburguer';
import NavLinks from 'components/NavLinks';
import logo from 'assets/images/logo.png';

export default function Nav() {
  const { navigation } = require('../configs/global');
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen((prev) => !prev);
  }

  return (
    <nav className="fixed">
      <div className="container w-full flex items-center">
        <div className="grow">
          <img
            src={logo}
            width={300}
            alt="Pharma Origins Education"
            className="w-[180px] lg:w-[300px]" />
        </div>
        <div>
          <button type="button" onClick={handleOpen} className="p-1 lg:hidden">
            <Hamburguer active={isOpen} />
          </button>
          <div className="hidden lg:block">
            <NavLinks items={navigation} />
          </div>
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            className="left-0 top-[92px] bg-brand-dark-blue w-full h-full px-6 py-3">
            <NavLinks 
              items={navigation}
              onClick={handleOpen} />
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
}