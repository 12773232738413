import icon from 'assets/images/icon-document.svg';

export default function Course({ onClick, opened }) {
  return (
    <section id="curso-certificado" className="scroll-m-10">
      <div className="container">
        <div className="flex flex-col lg:flex-row gap-10 lg:gap-[150px] pb-10 px-6 lg:px-0">
          <div className="order-last lg:order-first">
            <h2 className="mb-8">Curso certificado de formación sobre cannabis medicinal para Profesionales de la Salud</h2>
            <div className="flex flex-col gap-3 mb-10">
              <h4 className="text-[24px] font-bold">Antecedentes</h4>
              <p>La Universidad Hebrea de Jerusalén (HUJI) es la universidad más antigua de Israel, fundada en 1925 por leyendas tan notables como Albert Einstein y Sigmund Freud. Hoy en día es la principal institución académica de Israel, que ofrece aprendizaje multidisciplinar y descubrimientos de vanguardia para un público internacional. Alberga más de 100 centros de investigación, 6 campus, 6 institutos médicos afiliados y es el centro de la investigación biotecnológica de Israel. Cuenta con 8 Premios Nobel, 1 Medalla Fields de Matemáticas, 1 Premio Turing de Informática y 1 Premio Abel.</p>
              <p>Los investigadores médicos de la Universidad Hebrea de Jerusalén lanzan un curso pionero sobre el cannabis, EduCann, para formar mejor a médicos, enfermeros, farmacéuticos y profesionales de la medicina sobre la investigación, los tratamientos y las prácticas con cannabinoides. Como líder mundial en la investigación y el desarrollo del cannabis, el Centro Multidisciplinar de Investigación sobre Cannabinoides (MCCR) de la Universidad Hebrea incluye al profesor Raphael Mechoulam, conocido como el "Padre de la Investigación sobre el Cannabis" por sus enormes descubrimientos en este campo durante los últimos 55 años, así como a los principales expertos, el profesor Yossi Tam de la Universidad Hebrea, y el profesor Elyad Davidson y el Dr. Yakir Rottenberg del Centro Médico Hadassah.</p>
            </div>
            <button 
              type="button" 
              className="bg-brand-green rounded-none font-normal text-2xl px-[60px] py-[11px]"
              onClick={onClick}>
                {opened ? 'Ver menos' : 'Ver más'}
            </button>
          </div>
          <div className="bg-[#F7F8F9] border border-brand-green p-5 lg:min-w-[390px] lg:max-h-[400px] gap-[26px] flex flex-col order-first lg:order-last">
            <div className="flex flex-col lg:flex-row gap-3 items-center">
              <div className="bg-brand-green rounded-xl w-16 h-16 flex items justify-center">
                <img src={icon} width={24} height={24} alt="" />
              </div>
              <h4 className="text-2xl font-bold">Modalidad</h4>
            </div>
            <div className="flex flex-col items-center justify-center gap-5 lg:gap-8">
              <ul className="list-disc list-inside font-bold text-base lg:text-lg">
                <li>Curso online: USD 500</li>
                <li>Curso online con mentoría: USD 750</li>
              </ul>
              <p className="text-center">La mentoría online de 3 horas mensuales durante 3 meses estará a cargo de la <b>Dra Raquel Peyraube</b></p>
              <a href="#inscripcion" className=" text-white font-roboto text-center bg-brand-green rounded-none font-normal text-xl lg:text-2xl px-[60px] py-[11px] w-full lg:w-auto">
                Inscripción
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}