import { useState } from 'react';
import Course from 'components/yissum/Course';
import More from 'components/yissum/More';
import Nav from 'components/yissum/Nav';
import Contact from 'components/yissum/Contact';
import Footer from 'components/yissum/Footer';
import Videos from 'components/yissum/Videos';

export default function Yissum() {
  const [showMore, setShowMore] = useState(false);

  const handleMore = () => {
    setShowMore(prev => !prev);
  }

  return (
    <>
      <Nav />
      <main>
        <Videos />
        <Course onClick={handleMore} opened={showMore} />
        {showMore && <More />}
        <Contact />
        <Footer />
      </main>
    </>
  );
}