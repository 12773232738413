import who from 'assets/images/who.png';

export default function Who() {
  return (
    <section className="bg-brand-dark-blue scroll-mt-[80px] lg:scroll-mt-[100px]" id="nosotros">
      <div className="container px-6 py-12 lg:pt-[45px] lg:pb-7 lg:px-10">
        <div className="lg:px-10 mb-4 lg:mb-0">
          <h2 className="text-white">Quiénes somos</h2>
        </div>
        <div className="flex flex-col lg:flex-row gap-[26px] items-center">
          <p className="text-white">Pharma Origins Education es una organización dedicada a la generación de recursos humanos calificados en el campo de la Endocannabinología, Cannabis y Cannabinoides, a través de la capacitación con los mejores estándares de calidad de la información científica y médica actualizados según el estado del arte.</p>
          <img src={who} alt="" width={493} />
        </div>
      </div>
    </section>
  );
}