export default function Mision() {
  return (
    <section>
      <div className="container grid lg:grid-cols-2 gap-6 lg:gap-[165px] px-6 py-12 lg:pt-16 lg:pb-[27px] lg:px-10">
        <div>
          <div className="mb-10">
            <h2>Misión</h2>
            <div className="bg-brand-blue h-1 w-12 rounded-full"></div>
          </div>
          <p>Proporcionar información precisa, imparcial y basada en evidencia sobre el SEC y el cannabis y sus derivados, considerando sus efectos beneficiosos y sus riesgos, buscando empoderar a las personas para que puedan tomar decisiones informadas y saludables sobre sus posibles usos.</p>
        </div>
        <div>
          <div className="mb-10">
            <h2>Visión</h2>
            <div className="bg-brand-blue h-1 w-12 rounded-full"></div>
          </div>
          <p>Crear una sociedad en la que el uso medicinal del cannabis sea comprendido y aceptado, con pacientes y profesionales de la salud trabajando juntos para maximizar sus beneficios terapéuticos.</p>
        </div>
      </div>
    </section>
  );
}