export default function Intro() {
  return (
    <section>
      <div className="container px-6 py-12 lg:pt-[75px] lg:px-10 lg:pb-7">
        <div className="lg:px-10">
          <h2>Introducción</h2>
          <div className="flex flex-row gap-7">
            <div className="bg-brand-blue rounded-full w-[7px] min-w-[7px] min-h-full h-auto"></div>
            <div className="flex flex-col gap-4 pt-5 pb-20">
              <p>La educación sobre el sistema endocannabinoide (SEC), el cannabis y sus derivados es crucial para comprender la fisiología de este sistema de señalización y los potenciales efectos beneficiosos en distintas condiciones clínicas y riesgos de los productos en base a cannabis.</p>
              <p>Es así que la formación para los distintos objetivos profesionales, ya sea la práctica clínica, la investigación o la producción de medicamentos, debe incluir información de calidad sobre la complejidad del SEC, la composición química de la planta de Cannabis Sativa L., y las acciones farmacológicas de los diferentes componentes cannabinoides y no cannabinoides, así como la farmacocinética de los distintos productos, sus posibles efectos adversos así co.mo el riesgo de desarrollar un Uso Problemático de Cannabis y CUD (Cannabis Use Disorder)</p>
              <p>Es de igual importancia conocer el estado del arte en este campo de conocimiento y contar con información precisa y basada en evidencia sobre el uso medicinal del cannabis, evitando la confusión de información de calidad con creencias y aspectos folklóricos.</p>
              <p>La inclusión de compuestos con acción en el SEC, ya sean naturales o sintéticos, debe realizarse con responsabilidad y esto supone que médicos y médicas entiendan cabalmente los riesgos derivados del uso de productos no controlados. De este modo, podrán acompañar a los y las pacientes para que accedan a productos de calidad controlada y se les oriente para la toma de las mejores decisiones sobre su salud.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}