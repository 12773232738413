export default function NavLinks({ items, onClick }) {
  return (
    <ul className="nav">
      {items.map(item =>
        <li key={item.text}>
          <a href={item.link} onClick={onClick}>{item.text}</a>
        </li>)
      }
    </ul>
  );
}