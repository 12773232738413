import VideoCard from './VideoCard';

export default function Videos() {
  const videos = require('../../data/videos');

  return (
    <>
      <div className="container">
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-9 mx-6 mb-20">
          {videos.map(video => <VideoCard {...video} />)}
        </div>
      </div>
    </>
  );
}