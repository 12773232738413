import React from 'react';
import ReactDOM from 'react-dom/client';
import 'assets/styles/index.css';
import { 
  createBrowserRouter, 
  RouterProvider 
} from 'react-router-dom';
import Home from 'routes/home';
import Yissum from 'routes/yissum';

const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/yissum',
    element: <Yissum />
  }
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
