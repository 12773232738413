import { useState } from 'react';

export default function VideoCard({ title, lesson, link, time }) {
  const [active, setActive] = useState(false);

  const handleHover = () => {
    setActive(prev => !prev);
  }

  return (
    <a href="#curso-certificado">
      <div
        className="border rounded bg-white p-1 pb-6"
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}>
        <div className="relative bg-slate-100 w-full h-[360px] card-parent">
          <div className="card"></div>
          <div className="bg-black p-2 text-white text-xs absolute bottom-2 right-2">
            {time}
          </div>
        </div>
        <div className="flex flex-col gap-3 pt-1">
          <h3 className={`text-sm font-bold ${!active && 'line-clamp-1'} `}>{title}</h3>
          <p className="text-xs">Lesson #{lesson}</p>
        </div>
      </div>
    </a>
  );
}