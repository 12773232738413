import { Link } from 'react-router-dom';

import logo from 'assets/images/logo-transparent.png';
import yissum from 'assets/images/logo-yissum.svg';
import university from 'assets/images/logo-university.svg';

export default function Nav() {
  return (
    <nav className="bg-transparent p-0">
      <div className="container py-5 lg:py-10">
        <div className="flex gap-3 lg:gap-[50px] flex-col lg:flex-row items-center">
          <img src={logo} width={269} alt="" className="w-1/2 lg:w-auto" />
          <img src={yissum} width={300} alt="" className="w-1/2 lg:w-auto" />
          <img src={university} width={300} alt="" className="w-1/2 lg:w-auto" />
        </div>
      </div>
      <div className="bg-brand-green">
        <div className="container py-6 text-right px-6">
          <Link to="/" className="text-white text-base lg:text-xl">Home</Link>
        </div>
      </div>
    </nav>
  );
}